import { createContext, useState } from 'react';

import AppRoutes from './components/routing/AppRoutes';

export const subscriberContext = createContext();

/**
 *
 *
 * @return {*}
 */
const App = () => {
  const [sideBarFull, setSideBarFull] = useState(true);
  return (
    <>
      <div className='container'>
        <subscriberContext.Provider
          value={{ sideBarFull, setSideBarFull }}
        >
          <AppRoutes />
        </subscriberContext.Provider>
      </div>
    </>
  );
};

export default App;
