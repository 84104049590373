import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { SpeedDial } from 'primereact/speeddial';
import { TabMenu } from 'primereact/tabmenu';
import styled from 'styled-components';

export const SearchInput = styled(InputText)``;
export const InputNum = styled(InputNumber)`
  .p-button {
    background: #bc3232 !important;
  }
  .p-inputtext {
    width: 100px !important;
  }
`;
export const OptionTabMenuStyle = styled(TabMenu)`
  width: auto;
  .p-menuitem-icon {
    color: black;
  }
  a.p-menuitem-link {
    font-weight: 100 !important;
    opacity: 0.7;
    font-size: 12px;
    padding: 8px !important;
  }
  .p-highlight .p-menuitem-icon {
    color: inherit;
  }
  .p-highlight .p-menuitem-link {
    opacity: 1;
    font-weight: 500 !important;
  }
`;
export const OptionMainTabMenuStyle = styled(TabMenu)`
  .p-menuitem-icon {
    color: black;
  }
  a.p-menuitem-link {
    font-weight: 100 !important;
    opacity: 0.7;
  }
  .p-highlight .p-menuitem-icon {
    color: inherit;
  }
  .p-highlight .p-menuitem-link {
    opacity: 1;
    font-weight: 500 !important;
  }
`;

export const ActionButton = styled(Button)`
  width: 1.8rem !important;
  background: #bc3232;
  &:hover,
  &:focus {
    background: #bc3232 !important;
    opacity: 0.8;
  }
  height: 1.8rem !important;
  .p-button-icon {
    font-size: 0.7rem;
  }
`;
export const PagButton = styled(Button)`
  width: 0.1rem !important;
  background: #bc3232;
  &:hover,
  &:focus {
    background: #bc3232 !important;
    opacity: 0.8;
  }
  height: 0.1rem !important;
  .p-button-icon {
    font-size: 0.7rem;
  }
`;
export const RefreshButton = styled(Button)`
  width: 1.1rem !important;
  background: #bc3232;
  &:hover,
  &:focus {
    background: #bc3232 !important;
    opacity: 0.8;
  }
  height: 1.1rem !important;
  .p-button-icon {
    font-size: 0.7rem;
  }
`;
export const ExpandButton = styled(Button)`
  width: 2.2rem !important;
  height: 2.2rem !important;
  .p-button-icon {
    font-size: 1.5rem;
  }
`;

export const FlatButton = styled(Button)`
  background: #bc3232;
  &:hover,
  &:focus {
    background: #bc3232 !important;
    opacity: 0.8;
  }
`;

export const CancelFlatButton = styled(Button)`
  background: ${(props) => props.theme.colors.button_gray};
  color: ${(props) => props.theme.colors.black};
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.button_gray} !important;
    color: ${(props) => props.theme.colors.black} !important;
    opacity: 0.8;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primary};
  width: 49%;
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.primary} !important;
    opacity: 0.8;
  }
`;
export const LogoutButton = styled(Button)`
  width: 1.8rem !important;
  color:black !important
  background: #ffffff;
  &:hover,
  &:focus {
    color:black !important
    background: #ffffff !important;
    opacity: 0.8;
  }
  height: 1.8rem !important;
  .p-button-icon {
    font-size: 0.7rem;
  }
`;
export const CancelButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.button_gray};
  color: ${(props) => props.theme.colors.black};
  width: 49%;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.button_gray} !important;
    color: ${(props) => props.theme.colors.black} !important;
    opacity: 0.8;
  }
`;

export const PositionalDiv = styled.div`
  display: flex;
  align-items: center;
  // position: absolute;
  // top: -6.2rem;
  // left: 43%;
`;

// SpeedDial Sttling for Books Page
export const StyleSpeedDial = styled(SpeedDial)`
  right: 5px;
  .p-speeddial-button.p-button.p-button-icon-only {
    width: auto;
    height: auto;
  }
`;
/* Loading Overlay */
export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;
